<template>
  <div class="contact_us">
    <div class="con_nav">
      <nav-tab :nav-data="navData" />
    </div>
    <div class="con_center">
      <ContactUsItem />
    </div>
    <div class="con_bottom">
      <layout-bottom />
    </div>
  </div>
</template>

<script>
import NavTab from '@/components/NavTab'
import ContactUsItem from '@/components/contactUs/contactUsItem'
import LayoutBottom from '@/components/LayoutBottom'
export default {
  components: {
    NavTab,
    ContactUsItem,
    LayoutBottom
  },
  data() {
    return {
      navData: {
        index: 2,
        list: [
          {
            name: 'Home',
            link: '/'
          },
          {
            name: 'About Us',
            link: '/loan'
          },
          // {
          //   name: 'Loan Introduction',
          //   link: '/faq'
          // }
          // {
          //   name: 'HOW IT WORKS',
          //   link: '/how_it_works'
          // },
          // {
          //   name: 'FAQ',
          //   link: '/faq'
          // },
          {
            name: 'Loan Introduction',
            link: '/contact_us'
          },
          {
            name: 'Advance Salary',
            link: '/'
          }
        ]
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 768px){
  .con_center{
    // padding-top: 88px;
  }
}
@media only screen and (max-width: 767px){}

</style>
